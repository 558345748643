import { Container, Box, Grid, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSpakeDispatch } from "spake-react-sdk";
import HeroGradient from "../components/gradient/HeroGradient";

import SignInButton from "../components/SignInButton";
import Demo from "./Demo";
import DemoProvider from "../Providers/DemoProvider";
import { themes } from "../theme";

const Hero = () => {
  const { localize } = useSpakeDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid>
      <HeroGradient />
      <Container>
        <Grid
          container
          directon="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          zIndex="1"
          position="relative"
          minHeight="750px"
          height="80vh"
          pb={themes.spacing.large}
          pt={themes.spacing.large}
        >
          <Grid item sx={{ marginTop: -13 }} md={4} sm={12}>
            <Grid container directon={"column"} alignItems={"center"}>
              <Grid item>
                <Typography
                  variant="h2"
                  fontWeight={isMobile ? 650 : 700}
                  sx={{ mb: themes.spacing.medium }}
                  mx={isMobile ? 1 : 0}
                >
                  {localize("header")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  sx={{ mb: themes.spacing.medium }}
                  ml={isMobile ? 1 : 0}
                  mr={isMobile ? 2 : 0}
                >
                  {localize("subHeader")}
                </Typography>
              </Grid>
              <Grid
                direction="row"
                spacing={2}
                container
                sx={{ pt: themes.spacing.small }}
                width={"100vw"}
              >
                <Grid item sm={12} md={7}>
                  <SignInButton
                    variant="contained"
                    sx={{ p: themes.spacing.small, width: "100%" }}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={0.8}
                    >
                      <Grid item>
                        <Typography sx={{ fontWeight: 500 }} color="white">
                          {localize("marketing_try_spake")}
                        </Typography>
                      </Grid>
                      {/* This is weird, not responding to alignItem center */}
                      <Grid item marginTop={0.9}>
                        <ArrowForwardIcon />
                      </Grid>
                    </Grid>
                  </SignInButton>
                </Grid>
                <Grid item sm={12} md={5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: themes.spacing.small,
                    }}
                  >
                    <PlayCircleOutlineIcon
                      fontSize="large"
                      sx={{ color: "#E0A072", mr: themes.spacing.tiny }}
                    />
                    <Typography
                      sx={{ mr: themes.spacing.tiny, fontWeight: 600 }}
                    >
                      {localize("marketing_demo")}
                    </Typography>
                    <Typography
                      align="center"
                      variant="subtitle2"
                      fontSize={12}
                      color={"#B0B0B0"}
                    >
                      {localize("marketing_demo_length")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!isMobile ? (
            <Grid item>
              <DemoProvider>
                <Demo />
              </DemoProvider>
            </Grid>
          ) : (
            <> </>
          )}
        </Grid>
      </Container>
    </Grid>
  );
};

export default Hero;
