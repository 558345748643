import { createContext, useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";

import AuthTokenContext from "../Auth/AuthProvider";
import { getTranslations } from "../Services/translations";

const DashboardProviderState = createContext();
const DashboardProviderDispatch = createContext();

const DashboardProvider = ({ children }) => {
  const token = useContext(AuthTokenContext);

  const { data: translationsResponse } = useQuery(
    "get_translations",
    async () => {
      const response = await getTranslations(token);

      return response;
    },
    {
      enabled: !!token,
    }
  );

  const translations = translationsResponse ?? [];

  const sortedTranslations = translations.reduce(
    (allTranslations, currentTranslation) => {
      const updatedTranslations = { ...allTranslations };
      if (currentTranslation.spakeScore > 0.6) {
        updatedTranslations.good = [
          ...updatedTranslations.good,
          currentTranslation,
        ];
      } else if (currentTranslation.spakeScore > 0.4) {
        updatedTranslations.mid = [
          ...updatedTranslations.mid,
          currentTranslation,
        ];
      } else {
        updatedTranslations.fukt = [
          ...updatedTranslations.fukt,
          currentTranslation,
        ];
      }

      return updatedTranslations;
    },
    { good: [], mid: [], fukt: [] }
  );

  const [currentTranslations, setCurrentTranslations] = useState(
    sortedTranslations.good
  );
  const state = useMemo(
    () => ({
      currentTranslations,
      sortedTranslations,
    }),
    [translations, currentTranslations, sortedTranslations]
  );

  const dispatch = useMemo(
    () => ({
      setCurrentTranslations,
    }),
    [setCurrentTranslations]
  );

  return (
    <DashboardProviderState.Provider value={state}>
      <DashboardProviderDispatch.Provider value={dispatch}>
        {children}
      </DashboardProviderDispatch.Provider>
    </DashboardProviderState.Provider>
  );
};

export default DashboardProvider;
export const useDashboardState = () => useContext(DashboardProviderState);
export const useDashboardDispatch = () => useContext(DashboardProviderDispatch);
