import { useState } from "react";
import { AppBar, Button, Typography, Grid, Drawer } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Dehaze } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  useSpakeState,
  useSpakeDispatch,
  SpakeLanguageSelector,
} from "spake-react-sdk";

import SignInButton from "./SignInButton";

const SpakeTopNav = () => {
  const navigate = useNavigate();
  const { strings, language } = useSpakeState();
  const { setLanguage } = useSpakeDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <AppBar
      color="transparent"
      component="nav"
      position="relative"
      sx={{ boxShadow: "none", zIndex: 1 }}
    >
      {isMobile ? (
        <Grid container direction="row" justifyContent={"flex-end"}>
          <Grid item>
            <Button onClick={() => setMobileMenuOpen(true)}>
              <Dehaze
                sx={{
                  color: "#027FF7",
                }}
              />
            </Button>
            <Drawer
              anchor={"top"}
              open={mobileMenuOpen}
              onClose={() => setMobileMenuOpen(false)}
            >
              <Grid
                container
                direction="column"
                spacing={2}
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    fontSize={26}
                    fontWeight={700}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Spake
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={() => {
                      navigate("/challenge");
                    }}
                  >
                    <Typography fontSize={14} fontWeight={400} color="#4A4B4F">
                      {strings?.product?.string}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={() => {
                      navigate("/subscribe");
                    }}
                  >
                    <Typography fontSize={14} fontWeight={400} color="#4A4B4F">
                      {strings?.pricing?.string}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={() => {
                      navigate("/docs");
                    }}
                  >
                    <Typography fontSize={14} fontWeight={400} color="#4A4B4F">
                      {strings?.docs?.string}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Drawer>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p="10px"
          height="80px"
          pb="16px"
        >
          <Grid item sx={{ ml: "100px" }}>
            <Grid container direction="row" spacing={2} alignItems="center">
              <Grid item>
                <Typography
                  sx={{ cursor: "pointer" }}
                  fontSize={26}
                  fontWeight={700}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Spake
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    navigate("/challenge");
                  }}
                >
                  <Typography fontSize={14} fontWeight={400} color="#4A4B4F">
                    {strings?.product?.string}
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    navigate("/subscribe");
                  }}
                >
                  <Typography fontSize={14} fontWeight={400} color="#4A4B4F">
                    {strings?.pricing?.string}
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    navigate("/docs");
                  }}
                >
                  <Typography fontSize={14} fontWeight={400} color="#4A4B4F">
                    {strings?.docs?.string}
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <SpakeLanguageSelector />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ mr: "50px" }}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <SignInButton>
                  <Typography>{strings?.signIn?.string}</Typography>
                </SignInButton>
              </Grid>
              <Grid item>
                <SignInButton variant="contained">
                  <Typography color="white" fontWeight={500}>
                    {strings?.getStarted?.string}
                  </Typography>
                </SignInButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </AppBar>
  );
};

export default SpakeTopNav;
