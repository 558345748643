import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSpakeDispatch } from "spake-react-sdk";
import { useNavigate } from "react-router-dom";

import colors from "../theme/colors";
import ExampleIDE from "./ExampleIDE";
import ExampleFrameworks from "./ExampleFrameworks";
import { Button } from "../components/common";

const Example = () => {
  const { localize } = useSpakeDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container>
      <Box
        mx={isMobile ? 0 : 15}
        borderRadius={isMobile ? 0 : 8}
        sx={{ py: "20px", backgroundColor: colors.common.blueDark }}
        px={5}
      >
        <Grid container item direction="column" spacing={5} alignItems="center">
          <Grid
            item
            width={isMobile ? "100%" : "80%"}
            marginTop={5}
            marginBottom={isMobile ? 0 : 5}
          >
            <Typography
              color={colors.common.whiteIsh}
              fontWeight={700}
              fontSize={isMobile ? 24 : 36}
            >
              {localize("example_header")}
            </Typography>
          </Grid>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column-reverse" : "row",
                justifyContent: "space-between",
                width: "80vw",
              }}
            >
              <Grid
                item
                container
                direction="column"
                spacing={isMobile ? 0 : 5}
                width={isMobile ? "100%" : "50%"}
                mx={isMobile ? 0 : 5}
                mt={isMobile ? 2 : 0}
                justifyContent="space-evenly"
              >
                <Grid item sx={{ mx: 0, mb: isMobile ? 2 : 0 }}>
                  <Typography
                    color={colors.common.whiteIsh}
                    fontWeight={600}
                    fontSize={isMobile ? 18 : 30}
                  >
                    {localize("example_p1")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color={colors.common.whiteIsh}
                    fontWeight={600}
                    fontSize={isMobile ? 18 : 30}
                  >
                    {localize("example_p2")}
                  </Typography>
                </Grid>
                {!isMobile && (
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate("/docs");
                      }}
                    >
                      {localize("example_button")}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Box
                display="flex"
                flexDirection={isMobile ? "column-reverse" : "column"}
              >
                <ExampleIDE />
                <ExampleFrameworks />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isMobile && (
        <Grid item width="100%" display="flex" justifyContent="center" mt={2}>
          <Button
            variant="contained"
            onClick={() => {
              navigate("/docs");
            }}
            sx={{ width: "95%" }}
          >
            {localize("example_button")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Example;
