import { Typography, TableRow, TableCell } from "@mui/material";

const APIKeyRow = ({ data }) => {

  const { key_prefix, is_active } = data;

  return (
    <TableRow>
      <TableCell>
        <Typography>{`${key_prefix}.***********************************`}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{is_active ? "true" : "false"}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default APIKeyRow;
