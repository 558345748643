import { VictoryPie } from "victory";
import { Box, Typography } from "@mui/material";

import { colors, themes } from "../theme";

export default function DashboardPieChart({ good, mid, fukt }) {
  return (
    <Box
      sx={{
        width: "35%",
        paddingLeft: themes.spacing.medium,
        paddingTop: themes.spacing.small,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ alignSelf: "center" }} variant="h6">
        At a glance...
      </Typography>
      <VictoryPie
        colorScale={[
          colors.common.green,
          colors.common.yellow,
          colors.common.orange,
        ]}
        data={[
          // TODO clean up label hacky workaround
          { label: " ", y: good },
          { label: " ", y: mid },
          { label: " ", y: fukt },
        ]}
      />
    </Box>
  );
}
