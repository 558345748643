import { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import codeStyle from "./codeStyle";
import Typist from "react-typist-component";
import { Button, Box, Grid, Grow, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircleIcon from "@mui/icons-material/Circle";

import { useDemoProvider } from "../Providers/DemoProvider";

import { useEffect } from "react";

export default function Demo() {
  return (
    <Box maxWidth="750px">
      <CLIEditorPair zIndex="3" />
      <FakeWebsite zIndex="-1" />
    </Box>
  );
}

const DemoCLI = () => {
  const [dotsDisabled, setDotsDisabled] = useState(true);
  const [writeDisabled, setWriteDisabled] = useState(true);
  const { setTypingComplete, typingComplete, resetProviderState } =
    useDemoProvider();
  return (
    <Box
      sx={{
        backgroundColor: "white",
        minHeight: "200px",
        borderRadius: 2,
        zIndex: "5",
        boxShadow: 4,
      }}
    >
      <Grid container direction="column">
        <TerminalHeader />
        <Grid container direction="column" spacing={1} mt={0.05} ml={0.05}>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <code>$</code>
              </Grid>
              <Grid item>
                <Typist
                  onTypingDone={() => {
                    setDotsDisabled(false);
                  }}
                >
                  <code>spake-cli translate</code>
                </Typist>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {!dotsDisabled && (
              <Typist
                onTypingDone={() => {
                  setWriteDisabled(false);
                }}
              >
                <code>.....................</code>
              </Typist>
            )}
          </Grid>
          <Grid item>
            {!writeDisabled && (
              <Typist
                onTypingDone={() => {
                  setTypingComplete(true);
                }}
              >
                <code>Successfully wrote to file</code>
              </Typist>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
const TerminalHeader = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor="#f0f2f9"
      px={1}
      sx={{
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      }}
    >
      <TerminalCircles />
      <Grid item>
        <Typography variant="body2">
          <code>zsh</code>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body">⌘⌥ 1</Typography>
      </Grid>
    </Grid>
  );
};

const TerminalCircles = () => {
  return (
    <Grid item>
      <CircleIcon sx={{ color: "#FF605C", width: "15px", mx: "1px" }} />
      <CircleIcon sx={{ color: "#FFBD44", width: "15px", mx: "1px" }} />
      <CircleIcon sx={{ color: "#00CA4E", width: "15px", mx: "1px" }} />
    </Grid>
  );
};

const TextEditor = () => {
  const { typingComplete, englishStrings, spanishStrings } = useDemoProvider();
  const [spanishCode, setSpanishCode] = useState(`{
  "products": "",
  "search": "",
  "header": "",
  "valueProp": "",
  "sectionHeader": ""
}`);

  useEffect(() => {
    if (typingComplete) {
      setSpanishCode(JSON.stringify(spanishStrings, null, 2));
    }
  }, [typingComplete]);

  return (
    <>
      <Grid
        container
        direction="row"
        sx={{ borderTopRightRadius: 12, borderTopLeftRadius: 12, boxShadow: 4 }}
        backgroundColor={"#0D2E4E"}
        height={"180px"}
      >
        <Grid item sx={{ width: "50%" }}>
          <SyntaxHighlighter
            height={"200px"}
            language="javascript"
            showLineNumbers
            wrapLines
            style={codeStyle}
            sx={{ fontSize: "12px" }}
          >
            {JSON.stringify(englishStrings, null, 2)}
          </SyntaxHighlighter>
        </Grid>
        <Grid item sx={{ width: "50%" }}>
          <SyntaxHighlighter
            sx={{ minHeight: "200px" }}
            language="javascript"
            showLineNumbers
            wrapLines
            style={codeStyle}
          >
            {spanishCode}
          </SyntaxHighlighter>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        p={1}
        sx={{
          borderBottomRightRadius: 12,
          borderBottomLeftRadius: 12,
          boxShadow: 4,
          backgroundImage: "linear-gradient(270deg, #6506FF 0%, #027FF7 100%)",
        }}
      >
        <Grid item sx={{ width: "50%" }} pl={3}>
          <Box>
            <Typography variant="body" fontWeight={"bold"} color={"#F4F4F4"}>
              src/strings/english.json
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{ width: "50%" }} pl={3}>
          <Box>
            <Typography variant="body" fontWeight={"bold"} color={"#F4F4F4"}>
              src/strings/español.json
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const FakeWebsite = (props) => {
  const { strings } = useDemoProvider();
  const photos = [
    "https://i.imgur.com/oTmoDjw.png",
    "https://i.imgur.com/3nCw3VB.png",
    "https://i.imgur.com/9zsrl0e.png",
    "https://i.imgur.com/UcYrmtT.png",
  ];
  return (
    <Box
      {...props}
      position="relative"
      backgroundColor="white"
      marginTop="-600px"
      borderRadius={4}
      boxShadow={4}
      left={50}
    >
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        spacing={2}
        pb={2}
        marginLeft={"0.1px"} // insane hack to fix spacing
        sx={{
          backgroundColor: "#007FFF",
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
        }}
        fontFamily={"DM Sans"}
      >
        <Grid item>
          <Typography
            variant="h3"
            color="white"
            fontFamily={"DM Sans"}
            fontWeight={600}
          >
            Doge Party
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="white" fontFamily={"DM Sans"}>
            About
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="white" fontFamily={"DM Sans"}>
            {strings.products}
          </Typography>
        </Grid>

        <Grid item container directon="row" justifyContent="flex-end" flex={1}>
          <Grid item pr={2}>
            <Typography
              sx={{ backgroundColor: "white", px: 2, borderRadius: 1 }}
              fontFamily={"DM Sans"}
            >
              {strings.search}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={3} ml={2}>
        <Grid item px={1} mt={1}>
          <Typography
            variant="h4"
            className="MainHeader"
            fontFamily={"DM Sans"}
            fontWeight={"bold"}
            color="#262626"
          >
            {strings.header}
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="flex-start"
          direction="row"
          width={"60%"}
        >
          <Grid item>
            <Typography
              variant="h6"
              className="Value Prop"
              fontFamily={"DM Sans"}
              color="#262626"
            >
              {strings.valueProp}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Typography
              variant="h6"
              className="SectioNheader"
              fontFamily={"DM Sans"}
              fontWeight={"bold"}
              color="#262626"
            >
              {strings.sectionHeader}
            </Typography>
          </Grid>
          <Grid item container direction="row" spacing={1} mt={1}>
            {photos.map((photo) => (
              <Grid item key={photo}>
                <Box
                  borderRadius={2}
                  component="img"
                  sx={{
                    height: 100,
                    width: 150,
                    objectFit: "cover",
                    backgroundColor: "grey.500",
                  }}
                  src={photo}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
const CLIEditorPair = () => {
  return (
    <Grid container direction="column" sx={{ marginTop: "90px" }} spacing={1}>
      <Grid item container direction="row" justifyContent={"flex-end"}>
        <Grid item width="45%">
          <DemoCLI />
        </Grid>
      </Grid>
      <Grid item>
        <TextEditor />
      </Grid>
    </Grid>
  );
};
