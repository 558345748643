import { Box } from "@mui/material";
import Scene from "./Gradient.js";
import { useEffect } from "react";

export default function HeroGradient() {
  useEffect(() => {
    const sc = new Scene({
      dom: document.getElementById("gradient-canvas"),
    });
  }, []);

  return (
    <Box
      id="gradient-canvas"
      style={{
        zIndex: "-1",
        position: "absolute",
        width: "100%",
        top: 1,
        height: "100%",
      }}
      maxHeight={{ md: "80vh", xs: "100vh" }}
    />
  );
}
