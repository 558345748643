import { Grid } from "@mui/material";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import SpakeTopNav from "../components/SpakeTopNav";
import ChallengeView from "../Challenge/ChallengeView";
import LandingPageDetails from "./LandingPageDetails";
import LandingPageDocsView from "./LandingPageDocsView";
import LandingPagePricingView from "./LandingPagePricingView";

const LandingPageContainer = () => (
  <BrowserRouter>
    <Grid container>
      <SpakeTopNav />
      <Routes>
        <Route path="/" element={<LandingPageDetails />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/challenge" element={<ChallengeView />} />
        <Route path="/docs" element={<LandingPageDocsView />} />
        <Route path="/subscribe" element={<LandingPagePricingView />} />
      </Routes>
    </Grid>
  </BrowserRouter>
);

function Terms() {
  window.location.replace(
    "https://spake-public.s3.us-west-2.amazonaws.com/Uncommon+Industries+Spake+Terms+of+Service.pdf"
  );
}

function Privacy() {
  window.location.replace(
    "https://spake-public.s3.us-west-2.amazonaws.com/Spake+Privacy+Policy.pdf"
  );
}

export default LandingPageContainer;
