import { createContext, useContext, useMemo, useState } from "react";
import { useQuery, useMutation } from "react-query";

import { getRandomChallenge, submitVote } from "../Services/pepsi-challenge";

const ChallengeProviderState = createContext();
const ChallengeProviderDispatch = createContext();

const ChallengeProvider = ({ children }) => {
  const [metrics, setMetrics] = useState(null);

  const { data: challengeResponse, refetch: refetchChallenge } = useQuery(
    "get_random_challenge",
    async () => {
      const response = await getRandomChallenge();

      return response;
    }
  );
  const { mutate: voteOnVariant } = useMutation({
    mutationFn: async (params) => {
      const response = await submitVote(params.challengeID, params.variantID);
      return response;
    },
    onSuccess: (response) => {
      setMetrics(response);
    },
  });

  const challenge = challengeResponse ?? {};

  const state = useMemo(
    () => ({
      challenge,
      metrics,
    }),
    [challenge, metrics]
  );
  const dispatch = useMemo(
    () => ({
      voteOnVariant,
    }),
    [voteOnVariant]
  );

  return (
    <ChallengeProviderState.Provider value={state}>
      <ChallengeProviderDispatch.Provider value={dispatch}>
        {children}
      </ChallengeProviderDispatch.Provider>
    </ChallengeProviderState.Provider>
  );
};
export default ChallengeProvider;

export const useChallengeProviderState = () => {
  const context = useContext(ChallengeProviderState);

  return context;
};

export const useChallengeProviderDispatch = () => {
  const context = useContext(ChallengeProviderDispatch);

  return context;
};
