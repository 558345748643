import { useEffect, useState } from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

import AddKeyButton from "../components/AddKeyButton"; // TODO figure out how to get this button into markdown.
import GettingStarted from "./GettingStarted.md";

export default function DocsView() {
  return (
    <>
      <TestView />
    </>
  );
}

function TestView() {
  const [content, setContent] = useState("");
  useEffect(() => {
    fetch(GettingStarted)
      .then((response) => response.text())
      .then((text) => {
        setContent(text);
      });
  }, []);

  return (
    <SyntaxHighlightedMarkdownView remarkPlugins={[remarkGfm]}>
      {content}
    </SyntaxHighlightedMarkdownView>
  );
}

function SyntaxHighlightedMarkdownView({ children }) {
  return (
    <ReactMarkdown
      children={children}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, "")}
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    />
  );
}
