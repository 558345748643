import axios from "axios";
import configData from "../config/Config";

export const getAllApiKeys = async (token) => {
  const url = `${configData.backend.url}apiKeys/all`;
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const createNewKey = async (token) => {
  const url = `${configData.backend.url}apiKeys/`;
  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};
