import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { themes } from "../theme";
import { useDashboardState } from "../Providers/DashboardProvider";
export default function DashboardTranslations({
  goodCount,
  midCount,
  fuktCount,
  setGoodTranslations,
  setMidTranslations,
  setBadTranslations,
}) {
  const { currentTranslations } = useDashboardState();
  const [sortedTranslations, setSortedTranslations] = useState([]);
  useEffect(() => {
    console.log("currentTranslations", currentTranslations);
    let sortedTranslations = currentTranslations.sort((a, b) => {
      return b.spakeScore - a.spakeScore;
    });
    setSortedTranslations(sortedTranslations);
  }, [currentTranslations]);

  const [filter, setFilter] = useState("good");
  const handleFilterChange = (event, newFilter) => {
    setFilter(newFilter);

    if (newFilter === "good") {
      setGoodTranslations();
    } else if (newFilter === "mid") {
      setMidTranslations();
    } else {
      setBadTranslations();
    }
  };

  return (
    <Box
      sx={{
        width: "80%",
        paddingLeft: themes.spacing.medium,
        paddingTop: themes.spacing.small,
        paddingRight: themes.spacing.small,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
      >
        <Typography variant="h6">Translations</Typography>
        {/* https://github.com/mui/material-ui/issues/21026  react is dum.*/}
        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilterChange}
        >
          <ToggleButton
            sx={{ backgroundColor: "#FCFCFC", borderRadius: 0 }}
            value={"good"}
          >
            <Box display="flex" alignItems="center">
              <Typography fontWeight={500} variant="body2">
                Good
              </Typography>
              <Typography
                fontWeight={600}
                fontSize={11}
                color={"#4BBF6B"}
              >{`(${goodCount})`}</Typography>
            </Box>
          </ToggleButton>
          <ToggleButton
            sx={{ backgroundColor: "#FCFCFC", borderRadius: 0 }}
            value={"mid"}
          >
            <Box display="flex" alignItems="center">
              <Typography fontWeight={500} variant="body2">
                Fine
              </Typography>
              <Typography
                fontWeight={600}
                fontSize={11}
                color={"#F2B83B"}
              >{`(${midCount})`}</Typography>
            </Box>
          </ToggleButton>
          <ToggleButton
            sx={{ backgroundColor: "#FCFCFC", borderRadius: 0 }}
            value={"fukt"}
          >
            <Box display="flex" alignItems="center">
              <Typography fontWeight={500} variant="body2">
                Ugly
              </Typography>
              <Typography
                fontWeight={600}
                fontSize={11}
                color={"#EC6150"}
              >{`(${fuktCount})`}</Typography>
            </Box>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Original</TableCell>
            <TableCell>Translation</TableCell>
            <TableCell>SpakeScore</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* TODO - truncate / badge / colors  */}
          {sortedTranslations.map((translation) => (
            <DashboardRow
              {...translation}
              key={translation.sourceText + translation.translatedText}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

const DashboardRow = (translation) => {
  return (
    <TableRow>
      <DashboardCell text={translation.sourceText} />
      <DashboardCell text={translation.translatedText} />
      <DashboardCell text={translation.spakeScore.toFixed(2)} />
      <DashboardCell text={translation.notes} />
    </TableRow>
  );
};

const DashboardCell = ({ text }) => {
  return (
    <TableCell>
      <Typography
        variant="body2"
        whiteSpace={"nowrap"}
        maxWidth={"100px"}
        overflow="hidden"
        textOverflow={"ellipsis"}
      >
        {text}
      </Typography>
    </TableCell>
  );
};
