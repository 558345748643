import { Box, Link } from "@mui/material";

import { colors, themes } from "../theme";

function DashboardHeader() {
  return (
    <Box
      sx={{
        backgroundColor: colors.common.beige,
        my: themes.spacing.medium,
        width: "100%",
      }}
    >
      <Box sx={{ padding: themes.spacing.medium }}>
        Welcome to the Spake beta, we're excited for you to try our new toy.{" "}
        <Link target="_blank" href="https://uncommon.industries/">
          Click Here
        </Link>{" "}
        if you have anything resembling a problem.
      </Box>
    </Box>
  );
}

export default DashboardHeader;
