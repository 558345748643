import { Container } from "@mui/material";
import SubscriptionView from "../subscription/SubscriptionView";

const LandingPagePricingView = () => (
  <Container>
    <SubscriptionView />
  </Container>
);

export default LandingPagePricingView;
