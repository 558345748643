import { AppBar, Box, Button, Typography, Grid, Drawer } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import colors from "../theme/colors";
const AuthorizedTopNav = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  return (
    <AppBar
      component="nav"
      position="relative"
      sx={{
        boxShadow: "none",
        zIndex: 1,
        height: "40px",
        backgroundColor: colors.common.blue,
      }}
    >
      <Grid container display="flex" justifyContent="space-between">
        <Box sx={{ ml: "34px", display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ cursor: "pointer", mr: "20px" }}
            fontSize={26}
            fontWeight={700}
            color={colors.common.white}
            onClick={() => {
              navigate("/");
            }}
          >
            Spake
          </Typography>
          <Typography
            sx={{ cursor: "pointer", mr: "20px" }}
            fontSize={14}
            fontWeight={700}
            color={colors.common.white}
            onClick={() => {
              navigate("/docs");
            }}
          >
            Docs
          </Typography>
          <Typography
            sx={{ cursor: "pointer", mr: "20px" }}
            fontSize={14}
            fontWeight={700}
            color={colors.common.white}
            onClick={() => {
              navigate("/APIKeys");
            }}
          >
            Keys
          </Typography>
          <Typography
            sx={{ cursor: "pointer", mr: "20px" }}
            fontSize={14}
            fontWeight={700}
            color={colors.common.white}
            onClick={() => {
              navigate("/subscribe");
            }}
          >
            Subscriptions
          </Typography>
        </Box>
        <Box sx={{ mr: "34px", display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ cursor: "pointer", mr: "20px" }}
            fontSize={14}
            fontWeight={700}
            color={colors.common.white}
            onClick={() => {
              logout();
            }}
          >
            Logout
          </Typography>
          {/* TODO Profile and Settings here */}
        </Box>
      </Grid>
    </AppBar>
  );
};

export default AuthorizedTopNav;
