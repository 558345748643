import { Box } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

import codeStyle from "./codeStyle";

const code = `function Index() {
    return (
        <SpakeProvider>
            <App />
        </SpakeProvider>
    )
};

function App() {
    const { localize, setLanguage } = useSpakeDispatch();

    return (
        <div>
            <button onClick={() => {
                setLanguage('es')
            }}> { localize('set to Spanish')} </button>
            <p> { localize('my_string') } </p>
            <p> { localize('my_other_string') } </p>
        </div>
    )
}
`;

const ExampleIDE = () => (
  <Box sx={{ width: "100%" }}>
    <Box sx={{ background: "#0D2E4E", p: 3, borderRadius: 3 }}>
      <SyntaxHighlighter
        height={"200px"}
        style={codeStyle}
        language="jsx"
      >
        {code}
      </SyntaxHighlighter>
    </Box>
  </Box>
);

export default ExampleIDE;
