import { useContext } from "react";

import { Button, Grid, Typography, Box } from "@mui/material";

import AuthTokenContext from "../Auth/AuthProvider";
import configData from "../config/Config";

import { getNewCheckoutSession } from "../Services/subscriptions";

function SubscriptionView() {
  return (
    <Grid container mt={3} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">Plans!</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row">
          {configData.products.map((product) => (
            <Grid item xs={12} md={4} key={product.product_price_id}>
              <SubscriptionCard product={product} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

const SubscriptionCard = (props) => {
  let token = useContext(AuthTokenContext);

  const handleClick = (priceID) => {
    getNewCheckoutSession(token, priceID);
  };
  const description = `${props.product.period} subscription to uncommon spake`;

  return (
    <Box
      width={200}
      height={400}
      sx={{ p: 2, bgcolor: "#FFFFFF", borderRadius: 2 }}
    >
      <Grid container direction="column">
        <Grid item xs={12}>
          <Typography variant="h5">Subscription</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{description}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{props.product.price}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => handleClick(props.product.product_price_id)}>
            Subscribe
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubscriptionView;
