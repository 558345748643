const productionConfig = {
  auth: {
    domain: "spake.us.auth0.com",
    clientId: "nz1FQ5OhM9Y3JArwHt39pZxmBhkwWVZl",
    audience: "https://spake-api.com",
  },
  backend: {
    host: "https://spake-backend.uncommon.industries",
    url: "https://spake-backend.uncommon.industries/api/v1/",
  },
};
export { productionConfig };
