import { createContext, useContext, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { APICreationModal } from "./APIKeyCreationModal";

import AuthTokenContext from "../Auth/AuthProvider";
import { getAllApiKeys, createNewKey } from "../Services/apiKeys";

const APIKeyProviderState = createContext();
const APIKeyProviderDispatch = createContext();

const APIKeyProvider = ({ children }) => {
  const [newKey, setNewKey] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const token = useContext(AuthTokenContext);

  const handleModalClose = () => {
    setModalOpen(false);
    setNewKey({});
  };

  const { data: apiKeysResponse, refetch: refetchKeys } = useQuery(
    "get_all_keys",
    async () => {
      const response = await getAllApiKeys(token);
      return response;
    },
    {
      enabled: !!token,
    }
  );

  const { mutate: addNewKey } = useMutation({
    mutationFn: async () => {
      const response = await createNewKey(token);
      return response;
    },
    onSuccess: (response) => {
      setNewKey(response.data);
      setModalOpen(true);
      refetchKeys();
    },
  });

  const apiKeysData = apiKeysResponse?.data ?? [];

  const state = useMemo(
    () => ({
      apiKeysData,
    }),
    [apiKeysData]
  );

  const dispatch = useMemo(
    () => ({
      addNewKey,
    }),
    [addNewKey]
  );

  return (
    <APIKeyProviderState.Provider value={state}>
      <APIKeyProviderDispatch.Provider value={dispatch}>
        <APICreationModal
          open={modalOpen}
          handleClose={handleModalClose}
          apiKey={newKey}
        />
        {children}
      </APIKeyProviderDispatch.Provider>
    </APIKeyProviderState.Provider>
  );
};

export default APIKeyProvider;

export const useAPIKeyProviderState = () => {
  const context = useContext(APIKeyProviderState);
  return context;
};

export const useAPIKeyProviderDispatch = () => {
  const context = useContext(APIKeyProviderDispatch);
  return context;
};
