import { Button } from "@mui/material";

import { useAPIKeyProviderDispatch } from "../Providers/APIKeyProvider";

const AddKeyButton = () => {
  const { addNewKey } = useAPIKeyProviderDispatch();

  return <Button onClick={addNewKey}>Add Key</Button>;
};

export default AddKeyButton;
