import { Box, Grid, Typography } from "@mui/material";
import { VictoryChart, VictoryBar, VictoryLine, VictoryLabel, VictoryVoronoiContainer } from "victory";
import { useSpakeDispatch } from "spake-react-sdk";

import { colors } from "../theme";
import { common } from "@mui/material/colors";

const BleuBarChart = () => {
  const { localize } = useSpakeDispatch();
  const data = [
    { x: "en-es", y: 60 },
    { x: "es-fr", y: 61 },
    { x: "fr-de", y: 55 },
    { x: "it-es", y: 70 },
  ];
  return (
    <Grid container alignItems="center">
      <Box>
        <Typography fontWeight={700} color={colors.primary}>
          BLEU
        </Typography>
        <Typography>{localize("blue_chart_score")}</Typography>
      </Box>
      <Grid item>
        <VictoryChart
          containerComponent={
            <VictoryVoronoiContainer
              style={{
                touchAction: "auto"
              }}
            />
          }
          domainPadding={{ x: 35 }}
        >
          <VictoryBar
            barRatio={0.95}
            style={{
              data: { fill: colors.primary },
            }}
            data={data}
          />

          <VictoryLine
            y={() => 50}
            // labels={[localize("blue_chart_label")]}
            // labelComponent={<VictoryLabel renderInPortal dx={370} dy={5} />}
            style={{
              data: { stroke: colors.common.green, strokeWidth: 6 },
            }}
          />
        </VictoryChart>
      </Grid>
    </Grid>
  );
};

export default BleuBarChart;
