import { Modal, Box, Typography, Button, Grid } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function APICreationModal(props) {
  return (
    <Modal open={props.open}>
      <Box sx={style}>
        <Grid container direction={"column"} spacing={2} align>
          <Grid item>
            <Typography variant="h3">Your New API Key</Typography>
          </Grid>
          <Grid item>
            <Typography>
              This is your Api Key. There are many like it but this one is
              yours.
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {" "}
              This is the only time you will have access to the entire key.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">Save it now.</Typography>
          </Grid>
          <Grid item>
            <Box bgcolor={"#F2F3F6"} borderRadius={2}>
              <Typography p={2}>{props.apiKey.key}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Button onClick={props.handleClose} variant="contained">
              I understand and have saved my key
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
export { APICreationModal };
