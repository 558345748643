import { Container } from "@mui/material";
import DocsView from "../Docs/Docs";

const LandingPageDocsView = () => (
  <Container>
    <DocsView />
  </Container>
);

export default LandingPageDocsView;
