import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useSpakeDispatch } from "spake-react-sdk";

import react from "./react.jpg";
import swift from "./swift.png";
import vue from "./vue.png";
import angular from "./angular.png";
import { colors } from "../theme";

const ExampleFrameworks = () => {
  const { localize } = useSpakeDispatch();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{ mt: "20px", mb: "10px" }}
    >
      <Grid item>
        <Box
          borderRadius={2}
          component="img"
          sx={{
            height: isMobile ? 45 : 75,
            width: isMobile ? 45 : 75,
            objectFit: "cover",
          }}
          src={react}
        />
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          backgroundColor: colors.common.greyLight,
          height: isMobile ? 45 : 75,
        }}
      />
      <Box
        borderRadius={2}
        component="img"
        sx={{
          height: isMobile ? 45 : 75,
          width: isMobile ? 45 : 75,
          objectFit: "cover",
        }}
        src={swift}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          borderRadius={2}
          component="img"
          sx={{
            height: isMobile ? 45 : 75,
            width: isMobile ? 45 : 75,
            objectFit: "cover",
          }}
          src={angular}
        />
        <Typography color={colors.common.greyLight}>
          {localize("example_ide_coming_soon")}
        </Typography>
      </Box>
      <Box
        borderRadius={2}
        component="img"
        sx={{
          height: isMobile ? 45 : 75,
          width: isMobile ? 45 : 75,
          objectFit: "cover",
        }}
        src={vue}
      />
    </Grid>
  );
};

export default ExampleFrameworks;
