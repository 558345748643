// TODO confirm these routes

export const tasks = [
  {
    text: "Install SDK",
    url: "https://www.npmjs.com/package/spake-react-sdk",
  },
  {
    text: "Get an API key bruh",
    url: "https://github.com/UncommonIndustries/spake-cli#readme",
  },
  {
    text: "Install CLI tool",
    url: "https://github.com/UncommonIndustries/spake-cli#readme",
  },
  {
    text: "Gather your strings",
    url: "https://github.com/UncommonIndustries/spake-cli#readme",
  },
];
