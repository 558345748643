import axios from "axios";
import configData from "../config/Config";

export const getAllSubscriptions = async (token) => {
  console.log("please implement me");
};

export const getNewCheckoutSession = async (token, priceID) => {
  console.log(priceID);
  const url = `${configData.backend.url}subscriptions/checkout-session`;
  try {
    const response = await axios.post(
      url,
      {
        priceID: priceID,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    window.location.replace(response.data.sessionURL); // This feels completely insane but im not sure how else to handle it. @Acdoyle do you have thoughts on this?
  } catch (error) {
    console.log(error);
  }
};
