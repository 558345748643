import * as THREE from "three";

import fragment from "./shaders/fragment.glsl";
import vertex from "./shaders/vertex.glsl";

import glsl from "glslify";

let potentialSpakeColors = [
  "#FFF4CF",
  "#DAF8E6",
  "#D9EFD1",
  "#F4ECD7",
  "#DBC7A1",
  "#C3E7EF",
  "#FFFFFF",
];
potentialSpakeColors = potentialSpakeColors.map((color) => {
  return new THREE.Color(color);
});

export default class Scene {
  constructor(options) {
    this.scene = new THREE.Scene();
    this.container = options.dom;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize(this.width, this.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setClearColor(0xeeeeee, 1);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    // this.renderer = new THREE.WebGLRenderer({ antialias: true });

    // document.getElementById("container").appendChild(this.renderer.domElement);
    this.container.appendChild(this.renderer.domElement);
    this.camera = new THREE.PerspectiveCamera(
      60,
      this.width / this.height,
      0.01,
      2000
    );
    this.camera.position.set(0.09, 0.1, 0.1);

    this.time = 11;
    this.addMesh();
    this.addLights();

    this.isPlaying = true;

    this.render();
  }
  resize() {
    this.material.uniforms.resolution.value.x = this.renderer.domElement.width;
    this.material.uniforms.resolution.value.y = this.renderer.domElement.height;
  }

  addMesh() {
    this.geometry = new THREE.PlaneGeometry(1, 1, 300, 300);

    this.material = new THREE.ShaderMaterial({
      side: THREE.DoubleSide,

      uniforms: {
        time: { value: 0 },
        resolution: { type: "v4", value: new THREE.Vector4() },
        uColor: { value: potentialSpakeColors },
      },
      // transparent: true,
      // wireframe: true,

      vertexShader: glsl`uniform vec3 uColor[7];
      uniform float time;
      
      varying vec3 vColor;
      
      
      //	Simplex 3D Noise 
      //	by Ian McEwan, Ashima Arts
      //
      vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
      vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
      
      float snoise(vec3 v){ 
        const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
        const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);
      
      // First corner
        vec3 i  = floor(v + dot(v, C.yyy) );
        vec3 x0 =   v - i + dot(i, C.xxx) ;
      
      // Other corners
        vec3 g = step(x0.yzx, x0.xyz);
        vec3 l = 1.0 - g;
        vec3 i1 = min( g.xyz, l.zxy );
        vec3 i2 = max( g.xyz, l.zxy );
      
        //  x0 = x0 - 0. + 0.0 * C 
        vec3 x1 = x0 - i1 + 1.0 * C.xxx;
        vec3 x2 = x0 - i2 + 2.0 * C.xxx;
        vec3 x3 = x0 - 1. + 3.0 * C.xxx;
      
      // Permutations
        i = mod(i, 289.0 ); 
        vec4 p = permute( permute( permute( 
                   i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
                 + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
                 + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));
      
      // Gradients
      // ( N*N points uniformly over a square, mapped onto an octahedron.)
        float n_ = 1.0/7.0; // N=7
        vec3  ns = n_ * D.wyz - D.xzx;
      
        vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)
      
        vec4 x_ = floor(j * ns.z);
        vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)
      
        vec4 x = x_ *ns.x + ns.yyyy;
        vec4 y = y_ *ns.x + ns.yyyy;
        vec4 h = 1.0 - abs(x) - abs(y);
      
        vec4 b0 = vec4( x.xy, y.xy );
        vec4 b1 = vec4( x.zw, y.zw );
      
        vec4 s0 = floor(b0)*2.0 + 1.0;
        vec4 s1 = floor(b1)*2.0 + 1.0;
        vec4 sh = -step(h, vec4(0.0));
      
        vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
        vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;
      
        vec3 p0 = vec3(a0.xy,h.x);
        vec3 p1 = vec3(a0.zw,h.y);
        vec3 p2 = vec3(a1.xy,h.z);
        vec3 p3 = vec3(a1.zw,h.w);
      
      //Normalise gradients
        vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
        p0 *= norm.x;
        p1 *= norm.y;
        p2 *= norm.z;
        p3 *= norm.w;
      
      // Mix final noise value
        vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
        m = m * m;
        return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                      dot(p2,x2), dot(p3,x3) ) );
      }
      
      vec3 blendNormal(vec3 base, vec3 blend, float opacity){ 
          return (blend * opacity + base * (1.0-opacity));
      }
      
      void main() { 
          
          vec2 noiseCoord = uv * vec2(3.0,4.0);
      

          float noise = snoise(vec3(noiseCoord.x - time*0.1, noiseCoord.y, time*0.1)); // invert the noise cord here if you want to go to the right
          float tilt = 0.56*uv.y;
          float incline = uv.x*0.3;
          float offset = 0.5*incline*mix(-0.5,0.5, uv.y);
      
          // Fade noise to zero at edges
          // noise *= 1.0 - pow(abs(uv.y), 2.0);
      
          // Clamp to 0
          noise = max(0.0, noise);
      
          vColor = uColor[0];
      
          for (int i=0; i< 7; i++ ) { 
              float noiseFlow = 0.05 + float(i)*0.03;
              float noiseSpeed = 0.05 + float(i)*0.03;
              
              float noise = snoise(
                  vec3(
                      noiseCoord.x + time*noiseFlow,
                      noiseCoord.y,
                      time*noiseSpeed));
              vColor = blendNormal(vColor, uColor[i], pow(noise,2.0));
          }
      
          vec3 pos = vec3( position.x, position.y, position.z + noise*0.3-tilt+incline+offset );
      
          vec4 mvPosition = modelViewMatrix * vec4( pos, 1.0 );
      
          gl_Position = projectionMatrix * mvPosition;
      
      }`,
      fragmentShader: glsl`varying vec3 vColor;

      void main(){ 
          gl_FragColor = vec4(vColor, 1.0);
      }`,
    });

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.mesh);
  }
  addLights() {
    const light1 = new THREE.AmbientLight(0xffffff, 0.5);
    this.scene.add(light1);
    const light2 = new THREE.DirectionalLight(0xffffff, 0.5);
    light2.position.set(0.5, 0.0, 0.877);
    this.scene.add(light2);
  }
  stop() {
    this.isPlaying = false;
  }

  play() {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.render();
    }
  }

  render() {
    if (!this.isPlaying) return;
    this.time += 0.005;

    this.material.uniforms.time.value = this.time;

    window.requestAnimationFrame(this.render.bind(this));
    this.renderer.render(this.scene, this.camera);
  }
}
