import { withAuthenticationRequired } from "@auth0/auth0-react";

import { Outlet } from "react-router-dom";
import { Box, Container } from "@mui/material";

function LoggedinViewBase() {
  return (
    <Box display="flex" backgroundColor={"#f5f5f5"} height={"100vh"}>
      <Container>
        <Outlet />
      </Container>
    </Box>
  );
}

const LoggedinView = withAuthenticationRequired(LoggedinViewBase);

export default LoggedinView;
