import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSpakeDispatch } from "spake-react-sdk";
import BleuBarChart from "./BleuBarchart";

const QualityCard = () => {
  const { localize } = useSpakeDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container>
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justifyContent={"center"}
        sx={{ py: 5, marginBottom: "50px" }}
        spacing={5}
        maxWidth="100vw"
      >
        <Grid item container justifyContent="center" my={isMobile ? 0 : 5}>
          <Grid item>
            <Typography fontWeight={700} fontSize={24}>
              {localize("marketing_quality_header")}
            </Typography>
          </Grid>
          <Grid item />
        </Grid>
        <Grid item container justifyContent="space-evenly">
          <Grid item>
            <BleuBarChart />
          </Grid>
          <Grid item width={{ md: "30%", sm: "100%" }}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography variant="h5" fontWeight={700}>
                  {localize("marketing_quality_description_header")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight={600}>
                  {localize("marketing_quality_description_subheader")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight={600}>
                  {localize("marketing_quality_description_paragraph")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight={600}>
                  {localize("marketing_quality_description_footer")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default QualityCard;
