import { useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { Box, Typography, Grid, Paper, Popper } from "@mui/material";
import { useSpakeDispatch } from "spake-react-sdk";

import { useChallengeProviderState } from "../Providers/ChallengeProvider";

const ChallengeGraph = () => {
  const { localize } = useSpakeDispatch();
  const [anchorEl, setAnchorEl] = useState();
  const { metrics, challenge } = useChallengeProviderState();

  const realVersion = challenge.variants.find((version) => version.real);
  const spakeVersion = challenge.variants.find((version) => !version.real);

  const spakeWins = metrics[realVersion.id] < metrics[spakeVersion.id];

  return (
    <Grid
      container
      item
      justifyContent="center"
      alignItems="center"
      spacing={10}
      width="75%"
    >
      <Grid item>
        <PieChart
          data={challenge.variants.map((version) => ({
            title: `version${version.id}`,
            color: version.real ? "#4688EC" : "#3644BF",
            value: metrics[version.id],
          }))}
        />
      </Grid>
      <Grid container direction="column" item width="50%" spacing={5}>
        <Grid item>
          <Typography fontWeight={700} fontSize={24}>
            {localize(
              spakeWins
                ? "challenge_graph_headline_spake"
                : "challenge_graph_headline_canonical"
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            onMouseEnter={(event) => {
              const { currentTarget } = event;
              setAnchorEl(currentTarget);
            }}
            onMouseLeave={() => {
              setAnchorEl(undefined);
            }}
          >
            {localize("challenge_graph_subHeader")}
          </Typography>
          <Popper open={!!anchorEl} anchorEl={anchorEl} placement="top-start">
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <div />
              <Paper sx={{ width: "50%", alignItems: "" }}>
                <Typography>{challenge.original_text}</Typography>
              </Paper>
            </Box>
          </Popper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChallengeGraph;
