const themes = {
  spacing: {
    tiny: "5px",
    small: "10px",
    medium: "30px",
    large: "50px",
  },
};

export default themes;
