import { useEffect } from "react";
import { Container, Typography } from "@mui/material";

import DashboardHeader from "./DashboardBeta";
import DashboardGettingStarted from "./DashboardGettingStarted";
import DashboardGraphs from "./DashboardGraphs";
import DashboardProvider from "../Providers/DashboardProvider";
import colors from "../theme/colors";

export default function DashboardContainer() {
  useEffect(() => {
    // added this to update the root color to remove the white margins
    // feels kinda hacky but I got tired of fighting with css
    document.getElementById("root").style.backgroundColor =
      colors.common.whiteIsh;
  }, []);

  return (
    <DashboardProvider>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "70%",
        }}
      >
        <Typography variant={"h3"}>Dashboard</Typography>
        <DashboardHeader />
        <DashboardGettingStarted />
        <DashboardGraphs />
      </Container>
    </DashboardProvider>
  );
}
