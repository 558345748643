import axios from "axios";
import configData from "../config/Config";

export const getTranslations = async (token) => {
  const url = `${configData.backend.url}translations`;

  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
