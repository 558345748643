import {
  Divider,
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

function Footer() {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container>
      <Divider />
      <Grid
        container
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent={"space-between"}
        mt={1}
      >
        <Grid item container direction="row" spacing={2} md={6}>
          <Grid item>
            <Link href="/terms" underline="hover" color="#262626">
              <Typography color="#262626">Terms</Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link href="/privacy" underline="hover" color="#262626">
              <Typography color="#262626">Privacy</Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="mailto:info@uncommon.industries"
              underline="hover"
              color="#262626"
            >
              <Typography color="#262626">Contact</Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent={isMobile ? "flex-start" : "flex-end"}
          md={6}
        >
          <Grid item>
            <Typography fontWeight="500">
              Uncommon Industries, Inc © 2023
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Footer;
