import React from "react";

import { Container } from "@mui/material";

import APIKeysTable from "./APIKeysTable";
import AddKeyButton from "../components/AddKeyButton";

const APIKeysContainer = () => (
  <Container>
    <APIKeysTable />
    <AddKeyButton />
  </Container>
);


export default APIKeysContainer;
