import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../components/common";

const SignInButton = (props) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button {...props} onClick={() => loginWithRedirect()}>
      {props.children}

    </Button>
  );
};

export default SignInButton;
