import { Box } from "@mui/material";
import DashboardPieChart from "./DashboardPieChart";
import DashboardTranslations from "./DashboardTranslations";
import {
  useDashboardState,
  useDashboardDispatch,
} from "../Providers/DashboardProvider";
import { colors, themes } from "../theme";

export default function DashboardGraphs() {
  const { sortedTranslations, currentTranslations } = useDashboardState();
  const { setCurrentTranslations } = useDashboardDispatch();

  return (
    <Box
      sx={{
        display: "flex",
        mt: themes.spacing.medium,
        width: "100%",
        backgroundColor: colors.common.white,
        alignItems: "flex-start",
      }}
    >
      <DashboardPieChart
        good={sortedTranslations.good.length}
        mid={sortedTranslations.mid.length}
        fukt={sortedTranslations.fukt.length}
      />
      <DashboardTranslations
        translations={currentTranslations}
        goodCount={sortedTranslations.good.length}
        midCount={sortedTranslations.mid.length}
        fuktCount={sortedTranslations.fukt.length}
        setGoodTranslations={() => {
          setCurrentTranslations(sortedTranslations.good);
        }}
        setMidTranslations={() => {
          setCurrentTranslations(sortedTranslations.mid);
        }}
        setBadTranslations={() => {
          setCurrentTranslations(sortedTranslations.fukt);
        }}
      />
    </Box>
  );
}
