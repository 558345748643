import { createContext, useContext, useState, useEffect } from "react";

const DemoProviderState = createContext();

const DemoProvider = ({ children }) => {
  const englishStrings = {
    products: "Pups",
    search: "Search 🔍",
    header: "Find friends for your dog!",
    valueProp:
      "Match with local pups in your area for pup play dates! This is definitely a real app!",
    sectionHeader: "Pups!",
  };
  const spanishStrings = {
    products: "Pups",
    search: "Búsqueda 🔍",
    header: "¡Encuentra amigos para tu perro!",
    valueProp:
      "¡Combina con cachorros locales en tu área para fechas de juego de cachorros!",
    sectionHeader: "Los Cachorros",
  };

  const [strings, setStrings] = useState(englishStrings);
  const [typingComplete, setTypingComplete] = useState(false);
  const resetProviderState = () => {
    setTypingComplete(false);
    setStrings(englishStrings);
  };

  useEffect(() => {
    if (typingComplete) {
      setStrings(spanishStrings);
    }
  }, [typingComplete]);

  return (
    <DemoProviderState.Provider
      value={{
        strings,
        setTypingComplete,
        typingComplete,
        englishStrings,
        spanishStrings,
        resetProviderState,
      }}
    >
      {children}
    </DemoProviderState.Provider>
  );
};

export default DemoProvider;

export const useDemoProvider = () => {
  const context = useContext(DemoProviderState);
  return context;
};
