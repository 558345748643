import { useState } from "react";
import { Box, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { colors, themes } from "../theme";

export default function DashboardTaskRow({ task }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    if (task.url) {
      window.open(task.url, "_blank");
    }
  };

  return (
    <Box
      sx={{
        borderTop: `1px solid ${colors.common.beige}`,
        paddingY: themes.spacing.small,
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
      }}
      onClick={handleClick}
    >
      <Typography fontWeight="bold">{task.text}</Typography>
      {clicked && <CheckBoxIcon color="success" />}
    </Box>
  );
}
