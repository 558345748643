import { Box, Typography } from "@mui/material";
import DashboardTaskRow from "./DashboardTaskRow";

import { tasks } from "./DashboardTasks";
import { colors, themes } from "../theme";

export default function DashboardGettingStarted() {
  return (
    <Box sx={{ backgroundColor: colors.common.white, width: "100%" }}>
      <Box sx={{ padding: themes.spacing.medium }}>
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ mb: themes.spacing.small }}
        >
          Getting Started
        </Typography>
        {tasks.map((task) => (
          <DashboardTaskRow key={task.text} task={task} />
        ))}
      </Box>
    </Box>
  );
}
