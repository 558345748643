const colors = {
  primary: "#4460F4",
  common: {
    beige: "#f2ede7",
    blue: "#164C81",
    blueDark: "#092540",
    green: "#75DA6C",
    grey: "#F8F7F7",
    greyLight: "#D9D9D9",
    orange: "#EC6150",
    yellow: "#F2B83B",
    white: "#FFFFFF",
    whiteIsh: "#F5F5F5",
  },
};

export default colors;
