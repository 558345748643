import {
  Box,
  Container,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useSpakeDispatch } from "spake-react-sdk";

import { colors, themes } from "../theme";
import SignInButton from "../components/SignInButton";

const ValueProps = () => {
  const { localize } = useSpakeDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container sx={{ py: themes.spacing.large }}>
      <Grid container direction="column" spacing={isMobile ? 3 : 5}>
        <Grid>
          <Typography fontWeight={700} variant="h3" color={colors.primary}>
            {localize("value_props_header")}
          </Typography>
        </Grid>
        <Grid maxWidth={"100vw"}>
          <Typography fontWeight={700} variant="h6">
            {localize("value_props_subheader")}
          </Typography>
        </Grid>
        <Grid
          container
          direction={isMobile ? "column" : "row"}
          sx={{ my: isMobile ? themes.spacing.small : themes.spacing.large }}
        >
          <Grid sm={10} md={2.6}>
            <ValuePropCard
              header={localize("value_props_quality_header")}
              body={localize("value_props_quality_body")}
            />
          </Grid>
          <Grid>
            {!isMobile && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ backgroundColor: "black", height: "100%" }}
              />
            )}
          </Grid>
          <Grid sm={10} md={2.6}>
            <ValuePropCard
              header={localize("value_props_cheaper_header")}
              body={localize("value_props_cheaper_body")}
            />
          </Grid>
          <Grid>
            {!isMobile && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ backgroundColor: "black", height: "100%" }}
              />
            )}
          </Grid>
          <Grid sm={10} md={2.6}>
            <ValuePropCard
              header={localize("value_props_faster_header")}
              body={localize("value_props_faster_body")}
            />
          </Grid>
          <Grid>
            {!isMobile && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ backgroundColor: "black", height: "100%" }}
              />
            )}
          </Grid>
          <Grid sm={10} md={2.6}>
            <ValuePropCard
              header={localize("value_props_reach_header")}
              body={localize("value_props_reach_body")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          py: themes.spacing.large,
        }}
      >
        <Grid sm={12} md={3}>
          <SignInButton variant="contained" sx={{ mr: themes.spacing.medium }}>
            <Typography fontWeight={600} color={"white"} m={1}>
              {localize("value_props_button")}
            </Typography>
          </SignInButton>
        </Grid>
        <Grid md={6}>
          <Typography variant="h6">
            {localize("value_props_global_reach")}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const ValuePropCard = ({ header, body }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mx: { sm: themes.spacing.small, md: themes.spacing.medium },
      }}
    >
      <Typography
        mb={{ sm: themes.spacing.small, md: themes.spacing.medium }}
        fontWeight={700}
        variant="h6"
      >
        {header}
      </Typography>
      <Typography>{body}</Typography>
    </Box>
  );
};

export default ValueProps;
