import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";

import configData from "./config/Config.js";
import SpakeProvider from "spake-react-sdk";

const root = ReactDOM.createRoot(document.getElementById("root"));

const AppTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: ["Poppins"].join(","),
      color: "#212431",
    },
  },
});

const LOCAL_STORAGE_KEY = "localstorage";
const queryClient = new QueryClient();

root.render(
  <Auth0Provider
    domain={configData.auth.domain}
    clientId={configData.auth.clientId}
    audience={configData.auth.audience}
    redirectUri={window.location.origin}
    cacheLocation={LOCAL_STORAGE_KEY}
  >
    <React.StrictMode>
      <ThemeProvider theme={AppTheme}>
        <QueryClientProvider client={queryClient}>
          <SpakeProvider>
            <App />
          </SpakeProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </React.StrictMode>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
