import { Button as MUIButton } from "@mui/material";

const Button = (props) => (
  <MUIButton
    {...props}
    sx={{
      ...props.sx,
      textTransform: "none",
      background:
        props.variant === "contained" &&
        "linear-gradient(270deg, #6506FF 0%, #027FF7 100%)",
    }}
  >
    {props.children}
  </MUIButton>
);

export default Button;
