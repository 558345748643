import axios from "axios";
import configData from "../config/Config";

export const getRandomChallenge = async () => {
  const url = `${configData.backend.host}/public/challenges/random_challenge`;
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const submitVote = async (challengeID, variantID) => {
  const url = `${configData.backend.host}/public/challenges/${challengeID}/${variantID}`;
  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
