import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "@mui/material";

import { useAPIKeyProviderState } from "../Providers/APIKeyProvider";

import APIKeyRow from "./APIKeyRow";

const APIKeysTable = () => {
  const { apiKeysData } = useAPIKeyProviderState();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography>API Key</Typography>
          </TableCell>
          <TableCell>
            <Typography>Active</Typography>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {apiKeysData.map((data) => (
          <APIKeyRow key={`row-key-${data.key_prefix}`} data={data} />
        ))}
      </TableBody>
    </Table>
  );
};

export default APIKeysTable;
