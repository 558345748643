import { useState, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoggedinView from "./LoggedInMainView";
import LandingPage from "./LandingPage";
import Loading from "./components/Loading";
import AuthTokenContext from "./Auth/AuthProvider";
import APIKeyProvider from "./Providers/APIKeyProvider";

//route outlets
import APIKeysContainer from "./APIKeys/APIKeysContainer";
import DashboardView from "./Dashboard/index";
import DocsView from "./Docs/Docs";
import SubscriptionView from "./subscription/SubscriptionView";
import AuthorizedTopNav from "./components/AuthorizedTopNav";

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return isAuthenticated ? <AuthenticatedRoutedView /> : <LandingPage />;
}

function AuthenticatedRoutedView() {
  const [token, setToken] = useState("");

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getToken();
  });

  const getToken = async () => {
    const token = await getAccessTokenSilently();
    setToken(token);
  };

  return (
    <AuthTokenContext.Provider value={token}>
      <APIKeyProvider>
        <BrowserRouter>
          <AuthorizedTopNav />
          <Routes>
            <Route path="/" element={<LoggedinView />}>
              <Route path="/" element={<DashboardView />} />
              <Route path="APIKeys" element={<APIKeysContainer />} />
              <Route path="docs" element={<DocsView />} />
              <Route path="subscribe" element={<SubscriptionView />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </APIKeyProvider>
    </AuthTokenContext.Provider>
  );
}

export default App;
