import { useMemo, useState } from "react";
import { Box, Button, Typography, Grid, Grow } from "@mui/material";
import ChallengeProvider, {
  useChallengeProviderDispatch,
} from "../Providers/ChallengeProvider";
import { useChallengeProviderState } from "../Providers/ChallengeProvider";
import { shuffle } from "../utils/shuffle";
import ChallengeGraph from "./ChallengeGraph";

function ChallengeView() {
  return (
    <ChallengeProvider>
      <ChallengeViewContents />
    </ChallengeProvider>
  );
}

function ChallengeViewContents() {
  const { challenge, metrics } = useChallengeProviderState();

  let variants = useMemo(() => shuffle(challenge.variants), [challenge]); // I am extremely proud of figuring out this memoization to maintain the order of the two variants on state change.

  return (
    <Grid container direction="column" alignItems={"center"} spacing={4} mt={3}>
      <Grid item>
        <Typography variant="h4" color="#262626">
          Which of the following passages is better?
        </Typography>
        <Typography variant="body2" color="#262626">
          (however you define "better")
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="h6" color="#262626">
          {challenge.text_source}
        </Typography>
      </Grid>

      <Grid
        container
        item
        direction="row"
        justifyContent={"center"}
        spacing={8}
      >
        {variants.map((variant) => (
          <Grid item key={variant.id}>
            <ChallengeSelectionView
              challengeID={challenge.id}
              variant={variant}
            />
          </Grid>
        ))}
      </Grid>
      {!!metrics && <ChallengeGraph />}
    </Grid>
  );
}

function ChallengeSelectionView(props) {
  const { voteOnVariant } = useChallengeProviderDispatch();
  const { challenge, metrics } = useChallengeProviderState();
  const [selected, setSelected] = useState(false);
  const spakeTranslationColor = "#4460F4";
  const cannonicalTranslationColor = "#54CAFC";

  const border = selected ? 3 : 0;

  const getBorderColor = () => {
    if (selected && props.variant.real) {
      return { borderColor: cannonicalTranslationColor };
    }
    if (selected && !props.variant.real) {
      return { borderColor: spakeTranslationColor };
    }
  };

  let sharedBorderStyle = {
    border: border,
    borderRadius: 3,
    padding: 2,
    borderImageSlice: 1,
  };

  const specificBorderColors = getBorderColor();

  const submitVote = () => {
    setSelected(true);
    voteOnVariant({
      challengeID: challenge.id,
      variantID: props.variant.id,
    });
  };

  return (
    <Grid
      container
      direction="column"
      maxWidth="30vw"
      alignItems={"center"}
      spacing={2}
    >
      <Box sx={{ ...sharedBorderStyle, ...specificBorderColors }}>
        <Grid item className="variant-text" minHeight={"25vh"} width={"30vw"}>
          <Typography variant="h6" color="#262626">
            {props.variant.text}{" "}
          </Typography>
        </Grid>
      </Box>

      {!metrics && (
        <Grid item>
          <Button
            variant="contained"
            onClick={submitVote}
            sx={{ backgroundColor: "#4460F4", color: "#F2F3F6" }}
          >
            <Typography color={"white"} p={1}>
              Select
            </Typography>
          </Button>
        </Grid>
      )}
      {metrics && (
        <>
          <Grow in={metrics} {...(metrics ? { timeout: 400 } : {})}>
            <Grid item>
              <Typography variant="h6">
                {(
                  (metrics[props.variant.id] / metrics.challenge_count) *
                  100
                ).toFixed(2)}
                %
              </Typography>
            </Grid>
          </Grow>
          <Grow in={metrics} {...(metrics ? { timeout: 300 } : {})}>
            {/* trust me this looks a lot better than grouping the grows together*/}
            <Grid item>
              <Typography
                variant="h6"
                color={
                  props.variant.real
                    ? cannonicalTranslationColor
                    : spakeTranslationColor
                }
              >
                {props.variant.real
                  ? "Canonical Translation"
                  : "Spake Translation"}
              </Typography>
            </Grid>
          </Grow>
        </>
      )}
    </Grid>
  );
}

export default ChallengeView;
