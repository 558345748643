import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Hero from "./Hero";
import GlobalReach from "./GlobalReach.svg";
import QualityCard from "./QualityCard";
import Example from "./Example";
import ValueProps from "./ValueProps";
import Footer from "./Footer";

const LandingPageDetails = () => (
  <Grid container direction="column">
    <Grid item>
      <Hero />
    </Grid>
    <Grid item marginTop={-9}>
      <GlobalReachCard />
    </Grid>
    <Grid item>
      <QualityCard />
    </Grid>
    <Grid item>
      <Example />
    </Grid>
    <Grid item>
      <ValueProps />
    </Grid>
    <Grid item my={2}>
      <PithyMadeWithComponent />
    </Grid>
    <Grid item height="75px">
      <Footer />
    </Grid>
  </Grid>
);

function PithyMadeWithComponent() {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <Typography>
            Made with heartEmoji by{isMobile ? <br /> : " "}
            <Link href="https://uncommon.industries/">Uncommon Industries</Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

// TODO move me to a file
function GlobalReachCard() {
  return (
    <Box
      width="100%"
      backgroundColor="#F8F8F8"
      py={5}
      sx={{ display: "flex" }}
      justifyContent={{ xs: "flex-start", md: "center" }}
    >
      <Box
        component={"img"}
        src={GlobalReach}
        mx={{ xs: 5, md: 0 }}
        width={{ xs: "80vw", md: "50vw" }}
      ></Box>
    </Box>
  );
}

export default LandingPageDetails;
